<script>
import api from '@/command/api'
import moment from 'moment'
import TemplateHeaderTable from '@/components/TemplateHeaderTable'
export default {
  name: 'ticketSaleGate',
  data() {
    return api.command.getState()
  },
  mounted() {
    api.command.getList.call(this, {
      url: '/farmTicketGate/page',
      current: 1,
    })
  },
  methods: {
    getHeader() {
      return [
        {
          name: '名称',
          type: 'input',
          key: 'gateName',
        },
        {
          name: '设备编号',
          type: 'input',
          key: 'deviceNo',
        },
      ]
    },
    getColumns() {
      return [
        {
          dataIndex: 'id',
          title: 'ID',
          align: 'left',
          sorter: (a, b) => a.id - b.id,
        },
        {
          dataIndex: 'gateName',
          title: '闸机名称',
          align: 'left',
        },
        {
          dataIndex: 'deviceNo',
          title: '设备编号',
          align: 'left',
        },
        {
          dataIndex: 'ipAddress',
          title: 'IP地址',
          align: 'left',
        },
        {
          dataIndex: 'gateType',
          title: '类型',
          align: 'left',
          customRender: function (text, records) {
            return {
              0: '检票通道',
              1: '进口上报',
              2: '出口上报',
            }[text]
          },
        },
        {
          dataIndex: 'upDown',
          title: '状态',
          type: 'badge',
          onExport: (records) => {
            return ['上架', '下架'][records]
          },
          filters: [
            {
              text: '上架',
              value: '0',
            },
            {
              text: '下架',
              value: '1',
            },
          ],
          filterMultiple: false,
          render(data) {
            return {
              showDot: true,
              name: data == 0 ? '上架' : '下架',
              color: data == 0 ? 'green' : 'red',
            }
          },
        },
        {
          dataIndex: 'a9',
          title: '操作',
          type: 'buttonGroup',
          typeData: ({ records }) => {
            return [
              {
                name: records.upDown == 0 ? '下架' : '上架',
                dispaly: true,
                onClick: () => {
                  api.command.edit.call(this, {
                    url: `/farmTicketGate/${records.upDown == 0 ? 'down' : 'up'}?id=${records.id}`,
                  })
                },
              },
              {
                name: '编辑',
                dispaly: true,
                onClick: () => this.$router.push(`/commodityManagement/gateMachineManageDetail?id=${records.id}`),
              },
              {
                name: '删除',
                type: 'pop',
                dispaly: records.upDown == 1,
                popTitle: '是否确认删除?',
                onClick: () =>
                  api.command.del.call(this, {
                    url: `/farmTicketGate?id=${records.id}`,
                  }),
              },
            ].filter((e) => e.dispaly)
          },
        },
      ]
    },
    getButton() {
      return [
        {
          name: '添加',
          type: 'primary',
          icon: 'plus',
          onClick: () => this.$router.push('/commodityManagement/gateMachineManageDetail'),
        },
        {
          viewType: 'menu',
          name: '更多操作',
          isBatch: true,
          children: [
            {
              name: '批量删除',
              onClick: (data) => {
                let idList = data.map((e) => e.id)
                return api.command.del.call(this, {
                  url: '/farmTicketGate/deleteBatch',
                  params: { idList },
                })
              },
            },
            {
              name: '批量上架',
              onClick: (data) => {
                let idList = data.map((e) => e.id)
                return api.command.edit.call(this, {
                  url: '/farmTicketGate/upBatch',
                  params: { idList },
                })
              },
            },
            {
              name: '批量下架',
              onClick: (data) => {
                let idList = data.map((e) => e.id)
                return api.command.edit.call(this, {
                  url: '/farmTicketGate/downBatch',
                  params: { idList },
                })
              },
            },
          ],
        },
      ]
    },
  },
  render() {
    return (
      <TemplateHeaderTable
        headerData={this.getHeader()}
        tableColumns={this.getColumns()}
        button={this.getButton()}
        records={this.records}
      />
    )
  },
}
</script>
<style lang="less" scoped>
.product-order-item {
  display: flex;
  align-items: center;
  height: 30px;
  margin-bottom: 15px;
  &:nth-last-of-type(1) {
    margin-bottom: 0;
  }

  img {
    width: 30px;
    height: 30px;
    margin-right: 10px;
    border-radius: 15%;
  }
}
</style>
